<script lang="ts" setup>
const props = defineProps({
  isConsolidated: {
    type: Boolean,
    default: false,
  },
});

import { useModal } from "vue-final-modal";
import ReportProblemForm from "./Form.client.vue";

const showReportProblemModal = () => {
  const { open, close } = useModal({
    component: ReportProblemForm,
    attrs: {
      onClose: () => {
        close();
      },
    },
  });

  open();
};
</script>

<template>
  <BaseTextButton
    color="danger"
    @click="showReportProblemModal"
    v-tooltip="'Report Issue'"
  >
    <icon name="material-symbols:flag" size="25" />
  </BaseTextButton>
</template>

<style scoped></style>
